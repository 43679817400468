<template>
  <div v-if="topRecipe" class="secondary--text">
    <router-link
      :to="{
        name: getRecipeDetailsRouteName(topRecipe.category),
        params: { id: topRecipe.id }
      }"
    >
      <v-row
        align="center"
        justify="space-between"
        :class="[{ 'flex-column': isMobile }]"
      >
        <v-col
          cols="12"
          md="6"
          lg="6"
          class="d-flex flex-column"
          justify="space-between"
        >
          <div>
            <div
              class="text-body-1 text-md-h5 text-h6 font-weight-bold pb-md-4 pb-2"
            >
              {{ topRecipe.name }}
            </div>
            <p class="text-small text-sm-body-2 text-md-body-1 pb-md-4 pb-2">
              {{ topRecipe.description }}
            </p>
          </div>
          <div
            class="text-small text-sm-body-2 text-md-body-1 font-weight-bold"
          >
            {{
              `${topRecipe.ingredient} ${$t('label.ingredients')}  . 
           ${topRecipe.servePax} ${$t('label.servings')}  . 
           ${topRecipe.price} `
            }}
          </div>
        </v-col>
        <v-col cols="12" md="5" lg="5" class="text-right">
          <video
            v-if="['mp4', 'MP4', 'mov', 'MOV'].includes(topRecipe.assetType)"
            :src="topRecipe.asset"
            width="100%"
            autoplay
            loop
            controls
            muted
            playsinline
          ></video>
          <img v-else width="100%" :src="topRecipe.asset" />
        </v-col>
      </v-row>
    </router-link>
  </div>
</template>

<script>
  import common from '@/core/mixin/common.mixin';
  import { ROUTE_NAME } from '@/core/constants';
  import { RecipeType } from '@/core/constants/enums';
  import { i18nHelper } from '@/core/utils';
  import {
    RECIPE_GET_TOP_RECIPE,
    RECIPE_INITIAL_GET_TOP_RECIPE_STATE
  } from '@/core/store/recipe.module';

  export default {
    name: 'RecipeTopRecipe',
    mixins: [common],
    data: () => ({
      topRecipe: {}
    }),
    created() {
      this.initialTopRecipe();
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.mobile;
      },
      topRecipeComplete() {
        return this.$store.state.recipe.topRecipe.complete;
      }
    },
    watch: {
      topRecipeComplete() {
        let response = this.$store.state.recipe.topRecipe;
        if (response.complete) {
          this.topRecipe = response.data;
          this.topRecipeCompleteAction(response);
        }
      }
    },
    methods: {
      getRecipeDetailsRouteName(type) {
        let routeName = {
          [RecipeType.BAKERY]: ROUTE_NAME.RECIPE_BAKERY_DETAILS,
          [RecipeType.ASIAN]: ROUTE_NAME.RECIPE_ASIAN_DETAILS,
          [RecipeType.WESTERN]: ROUTE_NAME.RECIPE_WESTERN_DETAILS
        };
        return routeName[type];
      },
      topRecipeCompleteAction(response) {
        let type = null;
        let buttons = [];
        let title = i18nHelper.getMessage('label.topRecipe');
        let message = response.message;
        this.initialGetTopRecipeState();

        buttons = [
          {
            color: 'secondary',
            text: i18nHelper.getMessage('label.ok'),
            action: () => {
              this.closeAppDialogInfo();
            }
          }
        ];
        if (response.code == 0) {
          type = 'success';
        } else {
          type = 'error';
        }
        if (message) this.openAppDialogInfo(type, title, message, buttons);
      },
      getTopRecipe() {
        this.$store.dispatch(RECIPE_GET_TOP_RECIPE);
      },
      initialGetTopRecipeState() {
        this.$store.dispatch(RECIPE_INITIAL_GET_TOP_RECIPE_STATE);
      },
      initialTopRecipe() {
        this.getTopRecipe();
      }
    }
  };
</script>

<style lang="scss"></style>
