<template>
  <div class="content-wrapper pt-6 pb-12">
    <!-- 1st section is About intro recipe and show top recipe image ::start-->
    <!-- Recipe Top Recipe ::start -->
    <v-card flat class="slide-fade-x-enter enter-delay-50ms">
      <RecipeTopRecipe></RecipeTopRecipe>
    </v-card>

    <!-- Recipe Top Recipe ::end -->
    <!-- 1st section is About intro recipe and show top recipe image ::end-->

    <!-- this section is About intro recipe and show top recipe image ::start-->
    <div v-for="(i, index) in recipeType" :key="index">
      <v-card class="pt-7 pt-md-15 slide-fade-x-enter enter-delay-50ms" flat>
        <RecipeBasicList
          :type="i.type"
          :title="i.title"
          :subTitle="i.subTitle"
          :textMoreInfo="i.textMoreInfo"
          :redirectList="i.redirectList"
          :recipesObj="i.recipes"
        ></RecipeBasicList>
      </v-card>
    </div>
    <!-- this section is About intro recipe and show top recipe image ::end-->
  </div>
</template>

<script>
  import RecipeTopRecipe from '@/views/components/recipe/RecipeTopRecipe';
  import RecipeBasicList from '@/views/components/recipe/RecipeBasicList';
  import intersectMixin from '@/core/mixin/intersect.mixin';
  import commonMixin from '@/core/mixin/common.mixin';
  import { RecipeType } from '@/core/constants/enums';
  import { ROUTE_NAME } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    RECIPE_GET_RECIPES,
    RECIPE_INITIAL_GET_RECIPES_STATE
  } from '@/core/store/recipe.module';

  export default {
    name: 'RecipesList',
    mixins: [intersectMixin, commonMixin],
    components: {
      RecipeTopRecipe,
      RecipeBasicList
    },
    data: () => ({
      recipeType: [],
      recipesBakery: [],
      recipesAsian: [],
      recipesWestern: []
    }),
    created() {
      this.initailRecipe();
      this.getRecipes(RecipeType.BAKERY);
      this.getRecipes(RecipeType.ASIAN);
      this.getRecipes(RecipeType.WESTERN);
    },
    mounted() {
      this.onIntersectActionEnterXOnce();
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.mobile;
      },
      recipesComplete() {
        return this.$store.state.recipe.recipes.complete;
      }
    },
    watch: {
      recipesComplete() {
        let response = this.$store.state.recipe.recipes;
        if (response.complete) {
          let recipes = [];
          recipes.push(response.data);
          if (
            recipes.find((x) => x.find((i) => i.category == RecipeType.BAKERY))
          ) {
            this.recipesBakery = recipes.find((x) =>
              x.find((i) => i.category == RecipeType.BAKERY)
            );
          } else if (
            recipes.find((x) => x.find((i) => i.category == RecipeType.ASIAN))
          ) {
            this.recipesAsian = recipes.find((x) =>
              x.find((i) => i.category == RecipeType.ASIAN)
            );
          } else {
            this.recipesWestern = recipes.find((x) =>
              x.find((i) => i.category == RecipeType.WESTERN)
            );
          }
          this.recipesCompleteAction(response);
        }
      }
    },
    methods: {
      recipesCompleteAction(response) {
        let type = null;
        let buttons = [];
        let title = i18nHelper.getMessage('label.recipes');
        let message = response.message;

        this.initialGetRecipesState();
        buttons = [
          {
            color: 'secondary',
            text: i18nHelper.getMessage('label.ok'),
            action: () => {
              this.closeAppDialogInfo();
            }
          }
        ];
        if (response.code == 0) {
          this.initailRecipe();
          type = 'success';
        } else {
          type = 'error';
        }
        if (message) this.openAppDialogInfo(type, title, message, buttons);
      },
      initailRecipe() {
        this.recipeType = [
          {
            type: RecipeType.BAKERY,
            title: i18nHelper.getMessage(
              `enumsRecipeType.${RecipeType.BAKERY}`
            ),
            subTitle: i18nHelper.getMessage('messageRecipe.subTitleBakery'),
            textMoreInfo: i18nHelper.getMessage('messageRecipe.iWantBakeMore'),
            redirectList: ROUTE_NAME.RECIPE_BAKERY_LIST,
            recipes: this.recipesBakery
          },
          {
            type: RecipeType.ASIAN,
            title: i18nHelper.getMessage(`enumsRecipeType.${RecipeType.ASIAN}`),
            subTitle: i18nHelper.getMessage('messageRecipe.subTitleAsian'),
            textMoreInfo: i18nHelper.getMessage('messageRecipe.iWantCookMore'),
            redirectList: ROUTE_NAME.RECIPE_ASIAN_LIST,
            recipes: this.recipesAsian
          },
          {
            type: RecipeType.WESTERN,
            title: i18nHelper.getMessage(
              `enumsRecipeType.${RecipeType.WESTERN}`
            ),
            subTitle: i18nHelper.getMessage('messageRecipe.subTitleWestern'),
            textMoreInfo: i18nHelper.getMessage('messageRecipe.iWantCookMore'),
            redirectList: ROUTE_NAME.RECIPE_WESTERN_LIST,
            recipes: this.recipesWestern
          }
        ];
      },
      getRecipes(type) {
        let data = {
          category: type,
          perPage: this.isMobile ? 2 : 4
        };
        this.$store.dispatch(RECIPE_GET_RECIPES, { data });
      },
      initialGetRecipesState() {
        this.$store.dispatch(RECIPE_INITIAL_GET_RECIPES_STATE);
      }
    }
  };
</script>

<style lang="scss"></style>
